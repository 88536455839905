<template>
    <div class="container">
        <div style="display: flex; justify-content: space-between;">
            <div style="display: flex;">
                <el-input
                    v-model="keyword"
                    style="width: 250px;"
                    placeholder="请输入学校名称"
                >
                </el-input>
                <el-select v-model="school" placeholder="请选择" style="width: 100px; margin-left: 20px;">
                    <el-option value="0" label="小学"></el-option>
                    <el-option value="1" label="中学"></el-option>
                </el-select>
                <el-input
                    v-model="address"
                    style="width: 180px; margin-left: 20px;"
                    placeholder="请输入学校地址"
                >
                </el-input>
                <el-button type="primary" style="margin-left: 20px;" @click="getTableList">查询</el-button>
            </div>
            <div>
                <el-button type="primary" @click="download">导出</el-button>
            </div>
        </div>
        <!-- table -->
        <div style="margin-top: 20px;">
            <el-table :data="tableList" v-loading="dataListLoading"  style="width: 1490px;" height="600">
                <el-table-column label="序号" width="50" type="index" header-align="center" align="center" fixed="left"></el-table-column>
                <el-table-column v-for="(item, key) in columns" :key="key" :width="item.width || 'auto'" align="center" :label="item.label">
                    <template slot-scope="scope">
                        <div v-if="!item.slot">{{scope.row[item.prop]}}</div>
                        <div v-else>
                            <div v-if="item.prop === 'applyUrl'">
                                <a :href="scope.row[item.prop]" target="_blank">点击查看</a>
                            </div>
                            <div v-if="item.prop === 'review'">
                                <el-button type="text" @click="goReview(scope.row)">进行评审</el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <el-pagination
                layout="prev, pager, next"
                :total="total"
                @current-change="currentChange"
                @prev-click="prevClick"
                @next-click="nextClick"
            >
            </el-pagination>
        </div>
        <reviewPage ref="review"/>
    </div>
</template>

<script>
import { $activity } from "@/services"
import $cookies from "vue-cookies"
import {BASE} from '@/services/base'
import reviewPage from "./components/reviewPage.vue"
import XLSX from 'xlsx'
export default {
    components: {
        reviewPage
    },
    data() {
        return {
            columns:[
                { prop: 'schoolName', label: '学校名称',width:'200' },
                { prop: 'province', label: '省份'},
                { prop: 'address', label: '详细地址',width:'200' },
                { prop: 'applyUrl', label: '报名表',slot:true},
                { prop: 'review', label: '操作',slot:true},
            ],
            dataListLoading:false,
            value1:'',
            tableList:[],
            pageNum:1,
            pageSize:10,
            keyword:'',
            total:0,
            userId:$cookies.get("userInfo").id,
            action: BASE + '/server/common/upload',
            id:'',
            isreport:false,
            school:'',
            day:20,
            address:''
        }
    },
    created() {
        this.getTableList()
    },
    methods:{
        async getTableList() {
            if (!this.dataListLoading) {
                this.dataListLoading = true
                let params = {}
                params.pageNum = this.pageNum
                params.pageSize = this.pageSize
                params.keyword = this.keyword
                params.school = this.school
                params.userId = $cookies.get("userInfo").id
                params.days = this.day
                params.address = this.address
                let res = await $activity.signList(params)
                if (res.code === 200) {
                    this.total = res.data.total
                    this.tableList = res.data.records
                }
                this.dataListLoading = false
            }
        },
        currentChange(e) {
            this.pageNum = e
            this.getTableList()
        },
        prevClick(e) {
            this.pageNum = e
            this.getTableList()
        },
        nextClick(e) {
            this.pageNum = e
            this.getTableList()
        },
        async handleBefore(file) {
            if (!this.isreport) {
                this.$message.error('观测数据必须大于15条!!')
                return false
            }
            if (file.size / 1024 / 1024 > 2) {
                this.$message.error('上传图片不能大于2M')
                this.fileList = []
                return false
            }
            if (file.type !== 'application/pdf') {
                this.$message.error('只能上传pdf文件格式')
                this.fileList = []
                return false
            }
        },
        hanleSuccess(response) {
            if(response.code === 200) {
                let params = {}
                params.id = this.id
                params.reportUrl = response.data.url
                $activity.reportLunwen(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('上传成功')
                        this.getTableList()
                    } else {
                        this.$message.error('系统错误,请联系管理员')
                    }
                }).catch(() => {
                    this.$message.error('系统错误,请联系管理员')
                })
            }
        },
        async handleClick(row) {
            let params = {}
            params.pageNum = 1
            params.pageSize = 999
            params.date = ''
            params.userId = row.userId
            let res = await $activity.reportList(params)
            if (res.data.records.length < 15) {
                this.isreport = false
            } else {
                this.isreport = true
            }
            if (row.reportUrl) {
                window.open(row.reportUrl, '_blank')
            } else {
                this.id = row.id
            }
        },
        goReview(row) {
            this.$refs.review.setFlag(row)
        },
        // 导出
        async download () {
            let field = [
                'schoolName', 'province', 'address','teacher1','teacher1Contact',
                'teacher2','teacher2Contact','studentName','total','maxCount','isreportUrl'
            ]
            let label = {
                schoolName: "学校名称",
                province: "省份",
                address: "详细地址",
                maxTemTime:'出现时间',
                teacher1:'指导老师1',
                minTemTime:'出现时间',
                teacher1Contact:'指导老师1电话',
                teacher2:'指导老师2',
                teacher2Contact:'指导老师2电话',
                studentName:'参赛学生',
                total:'观测总天数',
                maxCount:'最高连续观测天数',
                isreportUrl:'是否上传论文'
            }
            let params = {}
            params.pageNum = 1
            params.pageSize = 99999
            params.userId = $cookies.get("userInfo").id
            params.keyword = ''
            params.days = 20
            params.school = this.school
            params.address = ''
            let reportData = await $activity.signList(params) 
            reportData.data.records.forEach(item => {
                if (item.reportUrl) {
                    item.isreportUrl = '是'
                } else {
                    item.isreportUrl = '否'
                }
            })
            let lastCol = XLSX.utils.encode_col(field.length - 1)
            let lastRow = XLSX.utils.encode_row(reportData.data.records.length)
            const ws = XLSX.utils.json_to_sheet(reportData.data.records, {
                header: field
            })
            const range = XLSX.utils.decode_range(ws['!ref'])
            ws['!ref'] = `A1:${lastCol}${lastRow}`
            for (let c = range.s.c; c <= range.e.c; c++) {
                const header = XLSX.utils.encode_col(c) + '1'
                ws[header].v = label[ws[header].v]
            }
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, `${this.device_type}`)
            XLSX.writeFile(wb, `报名学校表.xlsx`)
        },
    }
}
</script>

<style lang="less" scoped>
.container {
    padding: 40px;
}
</style>