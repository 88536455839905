<template>
    <el-dialog
        title="评审"
        :visible.sync="flag"
        width="50%"
    >
    <div>
        <el-descriptions class="margin-top" title="学校信息" :column="2" border>
            <el-descriptions-item label="学校名称">{{ schoolInfo.schoolName }}</el-descriptions-item>
            <el-descriptions-item label="省份">{{ schoolInfo.province }}</el-descriptions-item>
            <el-descriptions-item label="指导老师1">{{ schoolInfo.teacher1 }}</el-descriptions-item>
            <el-descriptions-item label="联系方式">{{ schoolInfo.teacher1Contact }}</el-descriptions-item>
            <el-descriptions-item label="指导老师2">{{ schoolInfo.teacher2 }}</el-descriptions-item>
            <el-descriptions-item label="联系方式">{{ schoolInfo.teacher2Contact }}</el-descriptions-item>
            <el-descriptions-item label="观测员">{{ schoolInfo.studentName }}</el-descriptions-item>
            <el-descriptions-item label="联系地址">{{ schoolInfo.address }}</el-descriptions-item>
            <el-descriptions-item label="观测天数">
                {{ schoolInfo.total }}
            </el-descriptions-item>
            <el-descriptions-item label="最高连续观测天数">
                 {{ schoolInfo.maxCount }}
            </el-descriptions-item>
            <el-descriptions-item label="观测论文">
                <a v-if="schoolInfo.reportUrl !== null" :href="schoolInfo.reportUrl" target="_blank">点击查看</a>
                <div v-else>暂未上传论文</div>
            </el-descriptions-item>
            <el-descriptions-item label="报名表下载">
                <a v-if="schoolInfo.applyUrl !== null" :href="schoolInfo.applyUrl" target="_blank">点击查看</a>
                <div v-else>暂未上传</div>
            </el-descriptions-item>
        </el-descriptions>
    </div>

    <!-- 学校观测数据 -->
    <div style="margin-top: 20px;">
        <div class="el-descriptions__title" style="display: flex; justify-content: space-between;">
            <div>观测数据</div>
            <div>
                <el-button type="primary" size="small" @click="download">导出</el-button>
            </div>
        </div>
        <el-table :data="tableList" v-loading="dataListLoading"  style="width: 100%;margin-top: 20px;">
            <el-table-column label="观测日期" prop="date" width="200" header-align="center" align="center" fixed="left"></el-table-column>
            <el-table-column label="上午" header-align="center">
                <el-table-column label="记录时间" prop="time1"></el-table-column>
                <el-table-column label="温度°C" prop="tem1"></el-table-column>
                <el-table-column label="湿度%"  prop="humidity1"></el-table-column>
                <el-table-column label="气压hPa" prop="barometer1"></el-table-column>
                <el-table-column label="风向°" prop="windDir1"></el-table-column>
                <el-table-column label="风速m/s" prop="windSpeed1"></el-table-column>
                <el-table-column label="降雨mm" prop="rain1"></el-table-column>
                <el-table-column label="光照(w/m)" prop="solarRadiation1" width="100"></el-table-column>
            </el-table-column>
            <el-table-column label="下午" header-align="center">
                <el-table-column label="记录时间" prop="time2"></el-table-column>
                <el-table-column label="温度°C" prop="tem2"></el-table-column>
                <el-table-column label="湿度%"  prop="humidity2"></el-table-column>
                <el-table-column label="气压hPa" prop="barometer2"></el-table-column>
                <el-table-column label="风向°" prop="windDir2"></el-table-column>
                <el-table-column label="风速m/s" prop="windSpeed2"></el-table-column>
                <el-table-column label="降雨mm" prop="rain2"></el-table-column>
                <el-table-column label="光照(w/m)" prop="solarRadiation2"  width="100"></el-table-column>
            </el-table-column>
            <el-table-column label="观测体会" prop="report" header-align="center" align="center"  width="600">

            </el-table-column>
            <el-table-column label="最美瞬间" header-align="center" align="center">
                <template slot-scope="scope">

                    <a v-if="scope.row.photos" :href="scope.row.photos" target="_blank" width="400">点击查看</a>
                    <div v-else>暂未上传</div>
                </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" width="200" header-align="center" align="center"></el-table-column>
        </el-table>
    </div>
    <div style="margin-top: 20px;">
        <el-pagination
            layout="prev, pager, next"
            :total="total"
            @current-change="currentChange"
            @prev-click="prevClick"
            @next-click="nextClick"
        >
        </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="flag = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
    </span>
    </el-dialog>
</template>

<script>
// import dayjs from "dayjs"
import $cookies from "vue-cookies"
import { $activity } from "@/services"
import XLSX from 'xlsx';
export default {
    data() {
        return {
            flag:false,
            schoolInfo:{},
            numdata:0,
            lunnum:0,
            tableList:[],
            dataListLoading:false,
            pageNum:1,
            pageSize:10,
            total:0,
            date:'',
            textarea:''
        }
    },
    methods:{
        setFlag(row) {
            this.schoolInfo = row
            this.flag = true
            this.getTableData()
        },
        async getTableData() {
            let params = {}
            params.pageNum = this.pageNum
            params.pageSize = this.pageSize
            params.date = this.date
            params.userId = this.schoolInfo.userId
            let res = await $activity.reportList(params)
            if (res.code === 200) {
                this.total = res.data.total
                this.tableList = res.data.records
            }
            this.dataListLoading = false
        },
        currentChange(e) {
            this.pageNum = e
            this.getTableData()
        },
        prevClick(e) {
            this.pageNum = e
            this.getTableData()
        },
        nextClick(e) {
            this.pageNum = e
            this.getTableData()
        },
        submit() {
            let params = {}
            params.userId = $cookies.get("userInfo").id
            params.userName = $cookies.get("userInfo").userName
            params.applyId = this.schoolInfo.id
            params.opinion = this.textarea
            params.score = this.numdata
            params.reportscore = this.lunnum
            console.log(params)
            $activity.review(params).then(res => {
                console.log(res)
                if (res.code === 200) {
                    this.$message.success('评分成功')
                    this.flag = false
                } else {
                    this.$message.error('系统错误,请联系管理员')
                }
            })
        },
        // 导出
        async download () {
            let field = [
                'schoolName', 'date', 'maxTem','maxTemTime','minTem',
                'time1','tem1','humidity1','barometer1','windDir1','windSpeed1','rain1','solarRadiation1' ,
                'time2','tem2','humidity2','barometer2','windDir2','windSpeed2','rain2','solarRadiation2' ,
                'report'
            ]
            let label = {
                schoolName: "学校名称",
                date: "观测时间",
                maxTem: "昨日最高温度’C",
                maxTemTime:'出现时间',
                minTem:'昨日最低温',
                minTemTime:'出现时间',
                time1:'上午记录时间',
                tem1:'上午温度°C',
                humidity1:'上午湿度%',
                barometer1:'上午气压hPa',
                windDir1:'上午风向°',
                windSpeed1:'上午风速m/s',
                rain1:'上午降雨mm',
                solarRadiation1:'上午光照w/m',
                time2:'下午记录时间',
                tem2:'下午温度°C',
                humidity2:'下午湿度%',
                barometer2:'下午气压hPa',
                windDir2:'下午风向°',
                windSpeed2:'下午风速m/s',
                rain2:'下午降雨mm',
                solarRadiation2:'下午光照w/m',
                report:'观测体会'
            }
            let reportData = await $activity.reportList({pageNum:1,pageSize:99999,userId: this.schoolInfo.userId,date:this.date}) 
            let lastCol = XLSX.utils.encode_col(field.length - 1)
            let lastRow = XLSX.utils.encode_row(reportData.data.records.length)
            const ws = XLSX.utils.json_to_sheet(reportData.data.records, {
                header: field
            })
            const range = XLSX.utils.decode_range(ws['!ref'])
            ws['!ref'] = `A1:${lastCol}${lastRow}`
            for (let c = range.s.c; c <= range.e.c; c++) {
                const header = XLSX.utils.encode_col(c) + '1'
                ws[header].v = label[ws[header].v]
            }
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, `${this.device_type}`)
            XLSX.writeFile(wb, `${this.tableList[0].schoolName}的观测记录.xlsx`)
        },
    }
}
</script>

<style>
.el-form-item__label {
    font-size: 18px;
}
.el-divider__text {
    font-size: 24px;
}
.el-input__inner {
    font-size: 18px;
}
</style>